import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Paper from '@mui/material/Paper'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { jsonCustomTheme } from './types'
import { SettingsApplicationsIcon, transformKeys } from './helpers'

export interface ShowQueryButtonProps extends ButtonProps {
  query: Record<string, unknown>;
}

export const ShowQueryButton: React.FC<ShowQueryButtonProps> = ({
  query,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'query-popover' : undefined;

  const transformedQuery = transformKeys(query);
  // adds additional fields to the query
  const wrappedQuery = { query: transformedQuery, flatten_catchall: false };

  return (
    <>
      <Button
        color='grey'
        variant='contained'
        data-cy='show-query-button'
        onClick={handleClick}
        {...buttonProps}
      >
        <>
          API 
          <SettingsApplicationsIcon />
        </>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            maxWidth: 'fit-content',
            p: 2,
            m: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <p><b>POST {import.meta.env.VITE_APP_SEARCH_DOMAIN.replace('search','api')}/screen_entity</b></p>
          <SyntaxHighlighter 
            language="json"
            style={jsonCustomTheme}
            customStyle={{
              margin: 0,
              padding: 0,
              background: 'transparent',
              fontSize: '14px',
              maxHeight: '400px',
              maxWidth: '500px',
            }}
          >
            {JSON.stringify(wrappedQuery, null, 2)}
          </SyntaxHighlighter>
        </Paper>
      </Popover>
    </>
  )
}

export default ShowQueryButton