import { TSelectionIDs } from './types'
import {
  ADVERSE_MEDIA,
  UBO,
} from '@/lib/constants/list_sources'
import {
  UBO_PERMISSION_NAME,
  ADVERSE_MEDIA_PERMISSION_NAME
} from '@/lib/authorization/ability'

type TOption = {
  subject: string
  dropDownName: string
}

const permissionToDropdownMap: TOption[] = [
  { subject: ADVERSE_MEDIA_PERMISSION_NAME, dropDownName: ADVERSE_MEDIA },
  { subject: UBO_PERMISSION_NAME, dropDownName: UBO },
]

/* Iterate over the dropdown options controlled by permissions, and given a function
to determine if the user is unauthorized or not, return a list of the unauthorized
dropdown options. */
export const getDisabledDropDownCategories = (
  denied: (subject: string) => boolean
): string[] => {
  return permissionToDropdownMap.reduce(
    (accumulator: string[], permission: TOption): string[] => {
      if (denied(permission.subject)) {
        accumulator.push(permission.dropDownName)
      }

      return accumulator
    },
    []
  )
}

type TListSourceSelectionSets = Omit<TSelectionIDs, 'all'>

type TListSourceSelectionLists = {
  listSourceCategoryIDs: number[]
  listSourceIDs: number[]
  listIDs: number[]
}

/**
 * translateListSourceSelections is a helper function for converting list source selections as sets
 * used internally to lists of IDs used for backend requests.
 *
 * @param {TListSourceSelectionSets} selections
 * @returns {TListSourceSelectionLists}
 */
export const translateListSourceSelections = (
  selections: TListSourceSelectionSets
): TListSourceSelectionLists => {
  const selectionLists: TListSourceSelectionLists = {
    listSourceCategoryIDs: [],
    listSourceIDs: [],
    listIDs: []
  }
  if (selections.category) {
    selectionLists.listSourceCategoryIDs = Array.from(selections.category)
  }
  if (selections['list-source']) {
    selectionLists.listSourceIDs = Array.from(selections['list-source'])
  }

  if (selections.list) {
    selectionLists.listIDs = Array.from(selections.list)
  }
  return selectionLists
}
