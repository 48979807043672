import React from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import { SupportDialog } from '@/components/lib/SupportDialog'
import { styled } from '@mui/material/styles'

import { Can } from '@/lib/authorization/authorizationContext'
import CloudUpload from '@mui/icons-material/CloudUpload'

interface ButtonComponentProps extends ButtonProps {
  isLoading?: boolean
}

const UploadIcon = styled(CloudUpload)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  isLoading = false,
  ...buttonProps
}) => {
  return (
    <Button
      variant='contained'
      component='span'
      fullWidth
      disabled={isLoading}
      color='grey'
      {...buttonProps}
    >
      Upload
      <UploadIcon />
    </Button>
  )
}

interface FileUploadButtonProps extends ButtonProps {
  isLoading?: boolean
  onFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFileReset: (e: React.MouseEvent<HTMLInputElement>) => void
}

const HiddenInput = styled('input')(() => ({
  display: 'none'
}))

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  isLoading,
  onFileSelect,
  onFileReset,
  ...buttonProps
}) => {
  return (
    <>
      <Can I='create' a='BatchSearch'>
        {() => (
          <>
            <HiddenInput
              id='file-upload-button'
              accept='.csv,.xlsx'
              type='file'
              onClick={onFileReset}
              onChange={onFileSelect}
            />
            <label htmlFor='file-upload-button'>
              <ButtonComponent isLoading={isLoading} {...buttonProps} />
            </label>
          </>
        )}
      </Can>
      <Can not I='create' a='BatchSearch'>
        {() => (
          <SupportDialog
            buttonComponent={(openModal) => (
              <ButtonComponent onClick={openModal} {...buttonProps} />
            )}
          />
        )}
      </Can>
    </>
  )
}

export default FileUploadButton
