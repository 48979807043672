import React from 'react'
import { styled } from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import Save from '@mui/icons-material/Save'
import Help from '@mui/icons-material/Help'
import Link from '@mui/material/Link'

const PREFIX = 'BatchScreenPlaceholder'

const classes = {
  downloadBatchTplButtonText: `${PREFIX}-downloadBatchTplButtonText`,
  downloadBatchTplButton: `${PREFIX}-downloadBatchTplButton`
}

const ButtonWrapper = styled('div')(() => ({
  marginTop: 8
}))

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [`& .${classes.downloadBatchTplButtonText}`]: {
    width: '750px'
  },
  [`& .${classes.downloadBatchTplButton}`]: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
}))

const tooltipText =
  'Each file must have at least one of the following headers: ' +
  'Name, Location, ID, or DOB. The order of the headers does not matter. ' +
  'Duplicate headers are not allowed. The Reference ID is not screened ' +
  'and can be used to track your input and output.'

const FileInstructions: React.FC = (props) => {
  return (
    <Root {...props}>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item>
          <Typography align='center' gutterBottom>
            Upload a{' '}
            <Link href='https://support.castellum.ai/hc/en-us/articles/18619012346132-Using-UTF-8-Encoded-CSV-Files-for-Batch-Screening-and-Scheduled-Screening'>
              UTF-8 encoded
            </Link>{' '}
            .CSV file or a .XSLX file. The file can have a maximum of 2,000 rows and 200
            columns.
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={tooltipText}>
            <Help fontSize='small' />
          </Tooltip>
        </Grid>
      </Grid>
      <img
        src={`${import.meta.env.VITE_APP_CDN_PATH}/page_data/screen_persons/upload_csv_tpl.png`}
        alt='Batch Screening Template'
      />
      <ButtonWrapper>
        <a
          href={`${import.meta.env.VITE_APP_CDN_PATH}/page_data/screen_persons/upload_csv_tpl.csv`}
          className={classes.downloadBatchTplButton}
          download
        >
          <Button variant='contained' color='grey' endIcon={<Save />}>
            Download Batch Screening Template
          </Button>
        </a>
      </ButtonWrapper>
    </Root>
  )
}

export default FileInstructions
