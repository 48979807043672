import React from 'react'
import { ListSourcesState } from '../types'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  appendix: {
    paddingTop: 16,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  categoryName: {
    textTransform: 'uppercase'
  },
  cell: {
    flex: 1,
    padding: 4,
    textAlign: 'center'
  },
  container: {
    marginTop: 18,
    fontSize: 12,
    border: '3 solid black',
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 6,
    paddingHorizontal: 6,
    flexWrap: 'wrap'
  },
  highlight: {
    backgroundColor: 'salmon'
  },
  headers: {
    width: '100%'
  },
  header: {
    lineHeight: 1.6,
    textDecoration: 'underline',
    width: '33%',
    textAlign: 'center',
    paddingBottom: 6
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  rowWrapper: {
    padding: '10 8'
  }
})

interface CategoryCellProps {
  category: string
  listSourcesScreened: number
  listsScreened: number
  hasHits: boolean
}

const CategoryCell: React.FC<CategoryCellProps> = ({
  category,
  listSourcesScreened,
  listsScreened,
  hasHits
}) => {
  const rowStyles = [styles.row]

  if (hasHits) {
    rowStyles.push(styles.highlight)
  }

  const isMedia = category === 'Adverse Media'
  const showCount = !isMedia || listsScreened === 0

  return (
    <View style={styles.rowWrapper}>
      <View style={rowStyles}>
        <Text style={[styles.cell, styles.categoryName]}>{category}</Text>
        <Text style={[styles.cell]}>
          {showCount ? listSourcesScreened : 'GLOBAL'}
        </Text>
        <Text style={[styles.cell]}>
          {showCount ? listsScreened : 'GLOBAL'}
        </Text>
      </View>
    </View>
  )
}

const CategoryHeaders: React.FC = () => (
  <View fixed style={[styles.row, styles.headers]}>
    <Text style={styles.header}>CATEGORIES</Text>
    <Text style={styles.header}>LIST SOURCES SCREENED</Text>
    <Text style={styles.header}>LISTS SCREENED</Text>
  </View>
)

const CategorySummary: React.FC<{ listSourcesState: ListSourcesState }> = ({
  listSourcesState
}) => {
  const summaryItems = []
  for (const [categoryName, categoryState] of listSourcesState.entries()) {
    let listSourcesScreened = 0
    let listsScreened = 0
    for (const listSourceState of categoryState.listSources.values()) {
      if (listSourceState.selected) {
        listSourcesScreened++
      }
      for (const listSelected of listSourceState.lists.values()) {
        if (listSelected) {
          listsScreened++
        }
      }
    }

    summaryItems.push(
      <CategoryCell
        key={categoryName}
        category={categoryName}
        listSourcesScreened={listSourcesScreened}
        listsScreened={listsScreened}
        hasHits={categoryState.hasHits}
      />
    )
  }

  return (
    <View style={styles.container}>
      <CategoryHeaders key='header-1' />
      <View>{summaryItems}</View>
      <Text fixed style={styles.appendix}>
        See Appendix for details on list sources
      </Text>
    </View>
  )
}

export default CategorySummary
