/**
 * The column of results displayed if a batch of screens has been submitted and it has at least 1 match
 */

import React from 'react'
import { VariableSizeList } from 'react-window'

import Paper from '@mui/material/Paper'

import { TScreenedEntity, TScreenParameters } from '@/lib/features/Screening'
import { ExcelReportButton } from '@/components/lib/Reporting'
import { TScreenedEntityListItem } from './types'
import ScreenedEntitiesListItem from './ScreenedEntitiesListItem'
import { styled } from '@mui/material/styles'
import ShowQueriesButton from '@/components/lib/ShowQuery/ShowQueriesButton'
import { formatDate, transformListSources } from '@/components/lib/ShowQuery/helpers'

const ELE_PER_PAGE = 11
const ITEM_SIZE = 80

type TItemData = {
  items: TScreenedEntityListItem[]
  selected: number
  onSelect: (idx: number | null) => void
  onAudit: () => void
  onWorkflow: () => void
}

type TScreenedEntitiesListItemRenderer = {
  index: number
  style: React.CSSProperties
  data: TItemData
}

const ResultsHeader = styled('div', { label: 'ResultsHeader' })(() => ({
  backgroundColor: 'black',
  textAlign: 'right'
}))

const StyledExportButton = styled(ExcelReportButton)(() => ({
  margin: 4
}))

const StyledQueryButton = styled(ShowQueriesButton)(() => ({
  margin: 4
}))

const ScreenedEntitiesListItemRenderer: React.FC<TScreenedEntitiesListItemRenderer> = ({
  index,
  style,
  data
}) => (
  <ScreenedEntitiesListItem
    style={style}
    key={data.items[index].index}
    idx={data.items[index].index}
    screenEntity={data.items[index].screenEntity}
    selected={data.selected}
    onSelect={data.onSelect}
    onAudit={data.onAudit}
    onWorkflow={data.onWorkflow}
  />
)

type TScreenedEntitiesListProps = {
  onAudit: () => void
  onSelect: (idx: number | null) => void
  onWorkflow: () => void
  screenEntities: TScreenedEntity[]
  screenParams: TScreenParameters | undefined
  selected?: number
  exportable?: boolean
}

function buildQueriesAndItems(screenEntities: TScreenedEntity[], params: TScreenParameters | undefined) {
  const queries: Array<Record<string, unknown>> = []
  const filteredItems: TScreenedEntityListItem[] = []
  
  let queryCount = 0
  for (let i = 0; i < screenEntities.length; i++) {
    if (screenEntities[i].totalHits > 0) {
      const screenedEntity = screenEntities[i]

      filteredItems.push({
        screenEntity: screenedEntity,
        index: i
      })

      // limit to the first 10 results
      if (params && queryCount < 10) {
        queries.push({
          name: screenedEntity.name || undefined,
          location: screenedEntity.location || undefined,
          dob: formatDate(screenedEntity.dob),
          idNumber: screenedEntity.idNumber || undefined,
          dobMonthsRange: params?.dobMonthsRange,
          minScore: params?.scoreThreshold,
          excludeWeakAkas: params?.weakAKAs,
          listSourceCategories: params?.listSourceCategories,
          listSources: transformListSources(params?.listSources),
        })
        queryCount++
      }
    }
  }
  return { queries, filteredItems }
}

const ScreenedEntitiesList: React.FC<TScreenedEntitiesListProps> = ({
  screenEntities,
  screenParams,
  selected = 0,
  onAudit,
  onSelect,
  onWorkflow,
  exportable = false
}) => {
  const { queries, filteredItems } = buildQueriesAndItems(screenEntities, screenParams)

  const itemData: TItemData = {
    items: filteredItems,
    selected: selected,
    onSelect: onSelect,
    onAudit: onAudit,
    onWorkflow: onWorkflow
  }

  /**
   * Get semi-final list height from its list item heights
   * @returns total height of list
   */
  const getHeight = () =>
    itemData.items.length > ELE_PER_PAGE
      ? ITEM_SIZE * ELE_PER_PAGE
      : ITEM_SIZE * itemData.items.length

  return (
    <ResultsHeader data-cy='search-results-container'>
      {screenParams && <StyledQueryButton queries={queries} />}
      <StyledExportButton
        reportType='Batch Search'
        disabled={!exportable}
        entityItems={itemData.items}
      />
      <Paper elevation={4}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width='100%'
          itemSize={() => ITEM_SIZE}
          overscanCount={5}
          itemCount={itemData.items.length}
        >
          {ScreenedEntitiesListItemRenderer}
        </VariableSizeList>
      </Paper>
    </ResultsHeader>
  )
}

export default ScreenedEntitiesList
