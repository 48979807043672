// Custom theme for syntax highlighting
export const jsonCustomTheme = {
  'code[class*="language-"]': {
    color: '#555', // value color
  },
  'property': {
    color: '#8a2727' // key color
  },
  'punctuation': {
    color: '#000000'
  }
};

export type JsonValue = string | number | boolean | null | JsonObject | JsonValue[];
export interface JsonObject {
  [key: string]: JsonValue;
}