import { styled } from '@mui/material/styles'

import MUIList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { dateRangeToString, getCountryName } from '@/lib/entity'
import UpgradeLink from '@/components/lib/Auth/UpgradeLink'
import { Country, DateRange, List, ListEntry } from 'proto-js'
import { Can } from '@/lib/authorization/authorizationContext'
import { formatMediaDate } from '@/lib/utils'
import Highlight from '@/components/lib/Highlight'
import { ListValue } from 'google-protobuf/google/protobuf/struct_pb'

const PREFIX = 'DetailsPane'

const classes = {
  root: `${PREFIX}-root`,
  subList: `${PREFIX}-subList`,
  subHeader: `${PREFIX}-subHeader`
}

const StyledMUIList = styled(MUIList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: '14px',
    paddingLeft: '14px'
  },
  [`& .${classes.subList}`]: {
    paddingBottom: '14px'
  },
  [`& .${classes.subHeader}`]: {
    color: theme.palette.text.secondary
  }
}))

interface EntityListEntryProps {
  entity: typeof ListEntry
}

interface ArticleDateProps {
  articleDate: Date | null
}

export const ArticleDate = ({ articleDate }: ArticleDateProps) => {
  if (!articleDate) return null

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        Date
      </Typography>
      <ListItem>
        <ListItemText
          primary={formatMediaDate(articleDate)}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    </div>
  )
}

interface ArticleSourceProps {
  source: string
}

export const ArticleSource = ({ source }: ArticleSourceProps) => {
  if (source === '') return null

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        Source
      </Typography>
      <ListItem>
        <ListItemText
          primary={source}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    </div>
  )
}

interface ProviderIdProps {
  providerId: string;
}

export const ProviderId = ({ providerId }: ProviderIdProps) => {
  if (!providerId) return null

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        Provider ID
      </Typography>
      <ListItem>
        <ListItemText
          primary={providerId}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    </div>
  )
}

const DOBsList = ({ entity }: EntityListEntryProps) => {
  const dobs = entity.getDobsList().map((d: typeof DateRange, i: number) => (
    <ListItem key={i}>
      <ListItemText
        primary={
          <Typography component='span' variant='body2'>
            <Highlight str={dateRangeToString(d)} wholeString={d.highlight} />
          </Typography>
        }
      />
    </ListItem>
  ))

  return (
    <Can I='view' a='Screen' field='dob' passThrough>
      {(allowed: boolean) => (
        <div className={classes.subList}>
          <Typography className={classes.subHeader} variant='subtitle2'>
            Date of Birth
          </Typography>
          {allowed ? (
            dobs
          ) : (
            <UpgradeLink additionalText='to see dates of birth' />
          )}
        </div>
      )}
    </Can>
  )
}

interface CitizenshipsListProps {
  entity: typeof ListEntry
}

const CitizenshipsList = ({ entity }: CitizenshipsListProps) => {
  const citizenships = entity
    .getCitizenshipsList()
    .map((c: typeof Country, i: number) => (
      <ListItem key={i}>
        <ListItemText
          primary={getCountryName(c)}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    ))

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        Citizenship
      </Typography>
      {citizenships}
    </div>
  )
}

interface ListsListProps {
  entity: typeof ListEntry
}

const ListsList = ({ entity }: ListsListProps) => {
  const lists = entity
    .getListSource()
    .getListsList()
    .map((p: typeof List, i: number) => (
      <ListItem key={i}>
        <ListItemText
          primary={p.getName()}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    ))

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        Lists
      </Typography>
      {lists}
    </div>
  )
}

interface ListSourcesListProps {
  entity: typeof ListEntry
}

const ListSourcesList = ({ entity }: ListSourcesListProps) => {
  const listSources = (
    <ListItem>
      <ListItemText
        primary={entity.getListSource().getName()}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )

  return (
    <div className={classes.subList}>
      <Typography className={classes.subHeader} variant='subtitle2'>
        List Source
      </Typography>
      {listSources}
    </div>
  )
}

export interface DetailsPaneProps {
  entity: typeof ListEntry
  isMedia: boolean
}

const DetailsPane = ({ entity, isMedia }: DetailsPaneProps) => {
  let articleDate: Date | null = null
  const articleDateMoment = entity.getPublishedDate() ?? entity.getLastUpdated()
  if (articleDateMoment) {
    articleDate = articleDateMoment.toDate()
  }
  const catchAll: Map<string, ListValue> = entity.getCatchAllMap()
  let sourceString: string = ''
  const source = catchAll.get('Source')
  if (source) {
    sourceString = source.getValuesList().map((v) => v.toJavaScript()).toString()
  }
  const showProviderId = isMedia && entity.getListSource().getListsList().length > 1

  return (
    <StyledMUIList className={classes.root}>
    {showProviderId && <ProviderId providerId={entity.getProviderId()} />}
    {isMedia && <ArticleDate articleDate={articleDate} />}
    {isMedia && <ArticleSource source={sourceString} />}
    {entity.getDobsList().length > 0 && <DOBsList entity={entity} />}
    {entity.getCitizenshipsList().length > 0 && (
      <CitizenshipsList entity={entity} />
    )}
    {entity.getListSource().getListsList().length > 0 && (
      <ListsList entity={entity} />
    )}
    {entity.getListSource().getName() && <ListSourcesList entity={entity} />}
  </StyledMUIList>
  )
}

export default DetailsPane
