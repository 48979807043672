export const SCREENING_INPUT_INDEX_TITLE = 'Screening Input Index'

export const REFERENCE_ID_TITLE = 'Reference ID'

export const SCREEN_TITLES = ['Name', 'Location', 'ID Number', 'DOB']

export const ALERT_TITLES = [
  'Alert Index',
  'Score',
  'ID',
  'List Source',
  'Category',
  'Lists',
  'Type',
  'Primary Name'
]

export const ALIAS_TITLES = ALERT_TITLES.concat([
  'Alias',
  'Primary',
  'Low Quality',
  'ID Type',
  'ID Number',
  'Issue Date',
  'Expiration Date'
])

export const LOCATION_TITLES = ALERT_TITLES.concat([
  'Location Type',
  'Address',
  'City',
  'Postal',
  'Region',
  'State',
  'Country'
])

export const ADDITIONAL_TITLES = ALERT_TITLES.concat([
  'Field Name',
  'Field Value'
])
