import { TScreenData } from "@/lib/features/Screening";
import { DEFAULT_DOB_MONTHS_RANGE, SLIDER_DEFAULT, WEAK_AKAS_DEFAULT } from "@/lib/constants/search";
import { JsonObject, JsonValue } from "./types";
import { SettingsApplications } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

export const camelToSnakeCase = (str: string): string => 
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

// loops through array and converts all keys to snake case
export const transformKeys = <T extends Record<string, unknown>>(obj: T): JsonObject => {
  const transformValue = (value: unknown): JsonValue => {
    if (Array.isArray(value)) {
      return value.map(item =>
        typeof item === 'object' && item !== null
          ? transformKeys(item as Record<string, unknown>)
          : item
      );
    }
    return value as JsonValue;
  };

  return Object.keys(obj).reduce<JsonObject>((acc, key) => {
    const snakeKey = camelToSnakeCase(key);
    acc[snakeKey] = transformValue(obj[key]);
    return acc;
  }, {});
};

// formats date to ISO string into the api request format
export const formatDate = (date: Date | string | undefined): string | undefined => {
  if (!date) return undefined;
  const d = typeof date === 'string' ? new Date(date) : date;
  return d.toISOString(); // Returns YYYY-MM-DDT00:00:00.000Z
};

// transforms listSources object to array of objects into the api request format
export function transformListSources(listSources: { [key: string]: string[] } | undefined) {
  if (Object.keys(listSources ?? {}).length === 0) return undefined;

  return Object.entries(listSources ?? {}).map(([name, lists]) => {
    return {
      name,
      lists: lists.map(name => ({ name }))
    }
  })
}

export const getScreenParameters = (screen: TScreenData['screen']) => {
  if ('parameters' in screen) {
    return screen.parameters;
  }
  return {
    dobMonthsRange: DEFAULT_DOB_MONTHS_RANGE,
    weakAKAs: WEAK_AKAS_DEFAULT,
    scoreThreshold: (SLIDER_DEFAULT/100).toFixed(2),
    listSourceCategories: undefined,
    listSources: undefined
  };
};

export const SettingsApplicationsIcon = styled(SettingsApplications)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))