import React from 'react'

import { HitsListPaginated } from '@/components/lib/HitsList'
import WorkflowDialog from '@/components/lib/Workflow'
import { useDialog } from '@/lib/hooks'
import { AuditTrailDialog } from '@/components/lib/AuditTrail'
import { TScreenData, isPersistedScreenedEntity } from '@/lib/features/Screening'
import { ExcelReportButton } from '@/components/lib/Reporting'
import { formatDate, getScreenParameters, transformListSources } from '@/components/lib/ShowQuery/helpers'

interface ResultsListProps {
  screenData: TScreenData
  countries: string[] | undefined
  entityTypes: string[] | undefined
  citizenships: string[] | undefined
  selected?: number
  onSelect: (idx: number | null) => void
  listSourceQueryString: string
  reportButton: JSX.Element
}

const ResultsList: React.FC<ResultsListProps> = ({
  screenData,
  countries,
  entityTypes,
  citizenships,
  selected,
  onSelect,
  listSourceQueryString,
  reportButton
}) => {
  const workflowDialog = useDialog()
  const auditTrailDialog = useDialog()
  const screenedEntity = screenData.entities[0]
  const params = getScreenParameters(screenData.screen);
  const query: Record<string, unknown> = {
    name: screenedEntity.name || undefined,
    location: screenedEntity.location || undefined,
    dob: formatDate(screenedEntity.dob),
    idNumber: screenedEntity.idNumber || undefined,
    dobMonthsRange: params.dobMonthsRange,
    minScore: params.scoreThreshold,
    excludeWeakAkas: params.weakAKAs,
    listSourceCategories: params.listSourceCategories,
    listSources: transformListSources(params?.listSources),
    countries: countries,
    citizenships: citizenships,
    entityTypes: entityTypes,
  }

  return (
    <>
      <HitsListPaginated
        hits={screenedEntity.hits}
        onSelect={onSelect}
        listSourceQueryString={listSourceQueryString}
        openWorkflow={workflowDialog.onOpen}
        openAudit={auditTrailDialog.onOpen}
        reportButton={reportButton}
        selected={selected}
        excelButton={
          <ExcelReportButton
            reportType='Advanced Search'
            entityItems={[{ index: 0, screenEntity: screenedEntity }]}
          />
        }
        query={query}
        totalHits={screenedEntity.totalHits}
        persisted={'id' in screenedEntity}
      />
      {isPersistedScreenedEntity(screenedEntity) && (
        <AuditTrailDialog
          screenedEntityID={screenedEntity.id}
          open={auditTrailDialog.open}
          onClose={auditTrailDialog.onClose}
        />
      )}

      <WorkflowDialog
        {...workflowDialog}
        screenData={screenData}
        screenedEntityIndex={0}
      />
    </>
  )
}

export default ResultsList
